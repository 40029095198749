import { Container, Row, Col } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { getPrice, RetrieveFilteredProductsFromOffer } from "../../utils/OdinPricingHelpers";
import Loader from "../../assets/image/gif/loader.gif"
import "./index.scss"
import "./ribbon.scss"
import GlobalContext from "../../context/GlobalContext";
import { navigate } from "gatsby";
import API from "../../api/api";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import styled from "styled-components";

import blackTick from './../../assets/image/svg/tick.svg';

const ProductDescriptionSmall = styled.small`
  font-size: 9px;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'DM Sans';
  font-weight: 500;
  img {
    margin-right: 4px;
    width: 12px;
  }
`;

export const RenderRecontractPricingBoxes = (props) => {
  const gContext = useContext(GlobalContext);
  const [premise, setPremise] = useState(null);
  const [showDetails, setShowDetails] = useState(null);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
    1200: { items: 5 },
  };

  useEffect(() => {
    if (gContext.goGetRegistrationData() && gContext.goGetRegistrationData().selectedProduct) {
      const updatedStepsData = { ...gContext.goGetSteps() };
      if (updatedStepsData.hasOwnProperty("2")) {
        updatedStepsData["2"] = {
          ...updatedStepsData["2"],
          stepVisited: true,
        };
        gContext.goSetSteps(updatedStepsData)
      }
    }

    if (props.checkoutLanding && props.sprn) {
      gContext.showFullScreenLoader();
      setShowDetails(null);
      API.get(`/CrmModule/v2.0/premises/${props.sprn}`)
        .then(
          (resp) => {
            setPremise(resp.data.data);
            gContext.hideFullScreenLoader()
          }
        )
        .catch(
          (error) => {
            gContext.hideFullScreenLoader()
            gContext.setAPIErrorMsg({
              title: 'Error',
              message: error.response ? error.response?.data.message : error
            })
            navigate('/')
          }
        )
    }
  }, []);

  const toggleDetails = (index) => {
    if (typeof window !== 'undefined' && window) {
      document.getElementById(`pricing-box-${index}`).classList.toggle("details-visible")
    }
  }


  const renderSpecialProductClass = (product) => {
    return (
      product.DisplayName.indexOf('You 1000') > -1 &&
      product.AvailableAddons.filter((addon) => addon.properties.DisplayName === 'YouMesh')
        .length &&
      product.ContractType === 'ANNUAL_18'
    );
  };

  const renderSelectedProductClass = (product) => {
    return gContext.goGetRegistrationData() &&
      gContext.goGetRegistrationData().selectedProduct &&
      gContext.goGetRegistrationData().selectedProduct.properties &&
      ((gContext.goGetRegistrationData().selectedProduct.properties.DisplayName + '-' + gContext.goGetRegistrationData().selectedProduct?.properties?.ContractType) ===
        (product.DisplayName + '-' + product.ContractType))
  };

  const singlePricingBox = (productToRender, product, ind) => (
    <>
      <Col xs={12} key={ind} className="pb-wrapper flex flex-column justify-between mb-3 p-0">
        <Col xs={12}>
          {renderSelectedProductClass(product) && <div className="tag">{(props.slug === 'checkout' || props.slug === 'recontract') ? 'Selected' : 'Loved by you'}</div>}
          <div className={`pb-header flex-grow-0 ${renderSelectedProductClass(product) && 'green-bg'}`}>
            {showDetails !== (product.DisplayName + product.ContractType) && <h2>{product.DisplayName}</h2>}
            {showDetails === (product.DisplayName + product.ContractType) && <h4>{product.DisplayName} | £{getPrice(product).toFixed(2)}</h4>}
          </div>
        </Col>
        <Col xs={12}>
          <Row className="pb-speed-wrapper">
            <Col sm={6}>
              <Row>
                <Col sm={12} className="m-auto text-center">{showDetails !== (product.DisplayName + product.ContractType) && <h4>{product.Description} Mbps</h4>}</Col>
                <Col sm={12} className="text-center"><p>Avg. upload speed</p></Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={12} className="m-auto text-center">{showDetails !== (product.DisplayName + product.ContractType) && <h4>{product.Description} Mbps</h4>}</Col>
                <Col sm={12} className="text-center"><p>Avg. download speed</p></Col>
              </Row>
            </Col>
          </Row>
          {showDetails !== (product.DisplayName + product.ContractType) &&
            <div className="pb-content text-center">
              <ProductDescriptionSmall className="mt-3">
                <img src={blackTick}/>No set-up fees
              </ProductDescriptionSmall>
              <ProductDescriptionSmall className="mb-2">
                <img src={blackTick}/>No in-contract price rises
              </ProductDescriptionSmall>
              <br/>
              <h4 className=" flex-grow-0">
                {/* {renderSpecialProductClass(product) ? '£' + getPrice(product).toFixed(2) : ('£' + getPrice(product).toFixed(2))} */}
                {'£' + getPrice(product).toFixed(2)}
              </h4>
              <span className="smallest-font align-center ml-1">per month</span>
              {(props.slug === 'business' || gContext.goGetRegistrationData()?.offer?.properties?.CustomerType === 'BUSINESS') && <span className="smallest-font align-center ml-1 pt-2">(ex. VAT)</span>}
            </div>
          }
        </Col>
        {showDetails === (product.DisplayName + product.ContractType) &&
          <Col xs={12} className="slide flex-1 flex flex-column justify-content-around align-content-center">
            <Row>
              <Col lg={6} md={6} sm={4} xs={4} className="value text-right">{product.Description}</Col>
              <Col lg={6} md={6} sm={8} xs={8} className="description">Mbps average upload & download speed</Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={4} xs={4} className="value text-right">{product.DescriptionLine1 ? product.DescriptionLine1.split(" ")[0] : 0}</Col>
              <Col lg={6} md={6} sm={8} xs={8} className="description">Month contract<br />Full terms & condition</Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={4} xs={4} className="value text-right">0</Col>
              <Col lg={6} md={6} sm={8} xs={8} className="description">No set up fees<br />No in contract price-rises</Col>
            </Row>
          </Col>
        }
        
        {
          (gContext.goGetRegistrationData()?.selectedProduct?.id == productToRender.id && props.slug == 'recontract') ?
          <Row className="pb-footer flex-grow-0 p-3">
            <Col sm={12} className="m-auto">
              <span className="pb-btn green-bg d-block disabled-link">Selected</span>
            </Col>
          </Row>
        :
          <Row className="pb-footer flex-grow-0 p-3">
          <Col sm={12} className="m-auto">
            <span className="pb-btn blue-bg d-block"
              onClick={() => {

                const mainProduct = gContext.goGetRegistrationData().orderItems.filter(item => item.properties.Type === 'BASE_PRODUCT');

                const prevProductSpeed = parseInt(mainProduct[0].properties.Description)
                const nextProductSpeed = parseInt(productToRender.properties.Description)

                let orderItemsNew = [];

                if (mainProduct) {
                  orderItemsNew = gContext.goGetRegistrationData().orderItems.filter(item => item.properties.Type !== 'BASE_PRODUCT');
                  orderItemsNew = orderItemsNew.concat(productToRender)
                } else {
                  orderItemsNew = gContext.goGetRegistrationData().orderItems.concat(productToRender)
                }

                if (prevProductSpeed < 920 && nextProductSpeed > 920) {
                  orderItemsNew = orderItemsNew.filter(item => item.properties.DisplayName !== 'YouMesh')
                  gContext.goSetAddonsSelected({
                    ...gContext.goGetAddonsSelected(),
                    'YouMesh': null,
                  });
                }

                if (prevProductSpeed > 920 && nextProductSpeed < 920) {
                  orderItemsNew = orderItemsNew.filter(item => item.properties.DisplayName !== 'YouMesh Pro')
                  gContext.goSetAddonsSelected({
                    ...gContext.goGetAddonsSelected(),
                    'YouMesh Pro': null,
                  });
                }

                // Move to Checkout Page
                if (gContext.goGetRegistrationData() && gContext.goGetRegistrationData().checkoutStep === 1) {
                  gContext.goSetRegistrationData({
                    ...gContext.goGetRegistrationData(),
                    orderItems: orderItemsNew,
                    offer: props.rawOffer || undefined,
                    selectedProduct: productToRender,
                    checkoutStep: 2,
                  });
                } else {
                  gContext.goSetRegistrationData({
                    ...gContext.goGetRegistrationData(),
                    orderItems: orderItemsNew,
                    selectedProduct: productToRender,
                    offer: props.rawOffer || undefined,
                    hearAboutUsText: 'Hear about us text',
                  });
                }
              }}
            >Select package</span>
          </Col>
        </Row>
        }
      </Col>
    </>
  );

  if (props.rawOffer) {
    let i = 0;
    return (
      <>
        <AliceCarousel
          mouseTracking
          responsive={responsive}
          disableButtonsControls={true}
          autoPlay={true}
          autoPlayDirection={'ltr'}
          autoPlayStrategy={'all'}
          autoPlayInterval={4000}
          controlsStrategy="alternate">
          {
            RetrieveFilteredProductsFromOffer({
              rawOffer: props.rawOffer.Product,
              productCategory: props.productCategory,
              contractType: props.contractType
            }).map((product, ind) => {
              product.properties.inc = i++;
              return singlePricingBox(product, product.properties, ind)
            })
          }
        </AliceCarousel>
      </>
    )
  } else {
    return (
    gContext.quoteLoading ?
      <Row>
        <Col sm={12} className="mt-2 mb-5 p-5 text-center">
          <h3 className="pb-5 mb-3 text-center">Retrieving products...</h3>
          <img src={Loader} alt="Loader" className="img-fluid mx-auto loader" />
        </Col>
      </Row>
      : 
      <Row>
        <Col sm={12} className="mt-2 mb-5 p-5 text-center">
          <h3 className="pb-5 mb-3 text-center">No products in offer</h3>
        </Col>
      </Row>
    )
    
  }
};
